/** @jsx jsx */
import {jsx} from "theme-ui"
import { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "~/components/layout"
import Seo from "~/components/seo"
import DotsIcon from "~/images/dots.inline.svg"
import FullCard from "~/components/branch/full"
import Map from "~/components/locations/map"
import Resources from "~/components/locations/resources"
import AtmCard from "~/components/atm/card"
import MarkerIcon from "~/images/icons/marker.inline.svg"

const LocationsPage = ({ data: { page, allWpBranch: { nodes: branches }, allWpAtm: { nodes: atms }, allWpLocation: { nodes: locations } } }) => {
  const [activeLocation, setLocation] = useState(locations[0])
  const center = activeLocation.description.split(',').map(num => Number(num))

  const sorted = [...branches].sort((a, b) => a.locations.nodes.some(({ termTaxonomyId }) => termTaxonomyId === activeLocation.termTaxonomyId) ? -1 : 0)

  return (
    <Layout>
      <Seo title={page.title} />
      <section
        style={{
          backgroundImage: `url('${page?.featuredImage?.node?.localFile?.childImageSharp?.fluid?.base64}')`,
          borderBottomRightRadius: '50% 10%'
        }}
        className="relative pt-44 pb-12 md:pb-0 bg-primary bg-cover bg-center overflow-hidden text-white z-10">
        <div className="container mx-auto px-8">
          <div className="flex flex-wrap items-center -mx-8">
            <div className="w-full flex flex-col md:w-1/2 px-8">
              <h1
                className="text-5xl md:text-7xl text-white mb-8 md:mb-20">{page.title}
              </h1>
              <small className="text-secondary mb-10">When it Matters</small>
            </div>
            <div className="flex md:justify-end w-full md:w-1/2 md:px-8">
              <ul
                className="flex list-none">
                <li
                  className="mx-4 md:mx-8">
                  <Link
                    className="pb-2 border-b border-secondary"
                    to={`/contact`}>
                    Contact
                  </Link>
                </li>
                <li
                  className="mx-4 md:mx-8">
                  <Link
                    className="pb-2 border-b border-secondary"
                    to={`/locations-atms/#branches`}>
                    Branch Locations
                  </Link>
                </li>
                <li
                  className="mx-4 md:mx-8">
                  <Link
                    className="pb-2 border-b border-secondary"
                    to={`/locations-atms/#atms`}>
                     ATM Locations
                  </Link>
                </li>
              </ul> 
            </div>
          </div>
        </div>
      </section>
      <section
        className="-mt-12">
        <Map
          center={center}
          branches={branches}
          atms={atms} />
        <div
          className="container mx-auto mt-12 px-8">
          <div className="flex -mx-8">
            <div
              className="flex items-center px-8 text-primary">
              <MarkerIcon />
              <span
                className="ml-4">Bank Branch Location</span>
            </div>
            <div
              className="flex items-center px-8 text-secondary">
              <MarkerIcon />
              <span
                className="ml-4">ATM Location</span>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-24 pb-12">
        <div className="container mx-auto px-8">
          <div className="flex flex-wrap -mx-8">
            <div className="relative w-full md:w-7/12 px-8">
              <ul
                className="sticky top-44 flex items-center p-0 list-none">
                { locations.map(location => {
                  const active = activeLocation.termTaxonomyId === location.termTaxonomyId
                  return (
                    <li
                      key={location.termTaxonomyId}
                      className="mr-3">
                      <button
                        onClick={e => {
                          e.preventDefault()
                          setLocation(location)
                        }}
                        className={`btn ${active ? 'btn-secondary' : 'btn-gray'}`}>
                        { location.name }
                      </button>
                    </li>
                  )
                })}
              </ul>
            </div> 
            <div className="w-full md:w-5/12 px-8">
              <Resources
                resources={page.locations.resources} />
            </div> 
          </div>
        </div>
      </section>
      <section
        id="branches"
        className="pb-24">
        <div className="container mx-auto px-8 border-b border-gray-200">
          <div className="flex flex-wrap -mx-8 mb-12">
            <div className="w-full px-8">
              <h6
                className="text-secondary mb-4">Visit Us</h6>
              <h1 className="text-6xl text-primary">Our Locations</h1>
            </div> 
          </div>
          <div className="relative flex flex-wrap -mx-8">
            <DotsIcon className="absolute w-32 h-32 top-0 left-0 -mt-8 -ml-8" />
            <div className="relative w-full px-8 z-10">
              <div className="flex flex-col -mx-8">
                { sorted.map(branch => (
                  <FullCard
                    key={branch.databaseId}
                    branch={branch} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="atms"
        className="pb-24">
        <div className="container mx-auto px-8">
          <div className="flex flex-wrap -mx-8 mb-12">
            <div className="w-full md:w-1/2 px-8">
              <h6
                className="text-secondary mb-4">Access to Cash</h6>
              <h1 className="text-6xl text-primary mb-12">ATM Locations</h1>
              <ul
                className="flex items-center p-0 list-none mb-12 md:mb-0">
                { locations.map(location => {
                  const active = activeLocation.termTaxonomyId === location.termTaxonomyId
                  return (
                    <li
                      key={location.termTaxonomyId}
                      className="mr-3">
                      <button
                        onClick={e => {
                          e.preventDefault()
                          setLocation(location)
                          const top = document.getElementById('atms').getBoundingClientRect().top + window.pageYOffset
                          window.scrollTo({
                            top
                          })
                        }}
                        className={`btn ${active ? 'btn-secondary' : 'btn-gray'}`}>
                        { location.name }
                      </button>
                    </li>
                  )
                })}
              </ul>
            </div>
            <div className="w-full md:w-1/2 px-8">
              <div
                sx={{
                  a: {
                    color: 'blue.600'
                  }
                }}
                dangerouslySetInnerHTML={{
                  __html: page.locations?.atmIntro
                }} />
            </div>
          </div>
          {/** ATM Locations */}
          <div className="flex flex-col">
            { locations.map(location => (
              <div
                key={location.termTaxonomyId}
                className={`flex flex-col mb-8 pb-8 border-b border-gray-100 ${activeLocation.termTaxonomyId === location.termTaxonomyId ? 'order-first' : 'order-last'}`}>
                <div
                  className="mb-6">
                  <h4
                    className="text-2xl text-primary">{location.name}</h4>
                </div>
                <div className="flex flex-wrap -mx-4">
                  { atms.filter(({ locations: { nodes }}) => nodes.some(({ termTaxonomyId}) => termTaxonomyId === location.termTaxonomyId)).map(atm => (
                    <div
                      className="px-4 w-full md:w-1/3 lg:w-1/4 mb-6"
                      key={atm.databaseId}>
                      <AtmCard
                        atm={atm} /> 
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>

        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query LocationsQuery($databaseId: Int!){
    page: wpPage(databaseId: { eq: $databaseId }){
      title
      content
      featuredImage {
        node {
          localFile{
            childImageSharp{
              fluid(toFormatBase64: WEBP, base64Width: 2000){
                base64
              }
            }
          } 
        }
      }
      locations {
        resources {
          slug
          title
          description
        }
        atmIntro
      }
    }
    allWpBranch(sort: { fields: date, order: ASC}) {
      nodes {
        databaseId
        title
        date(formatString: "YYYYMMDD")
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, quality: 100)
              }
            }
          }
        }
        branches {
          address {
            street
            city
            state
            zip
          }
          phone
          tollFree
          fax
          hours
          longitude
          latitude
        }
        locations {
          nodes {
            termTaxonomyId
          }
        }
      }
    }
    allWpAtm(sort: { fields: menuOrder, order: ASC }){
      nodes {
        databaseId
        title 
        atmFields {
          address
          latitude
          longitude
        }
        locations {
          nodes {
            termTaxonomyId
          }
        }
      }
    }
    allWpLocation(sort: { fields: name,  order: ASC }){
      nodes{
        termTaxonomyId
        name
        slug
        description
      }
    }
  }
`
export default LocationsPage