/** @jsx jsx */
import {jsx} from "theme-ui"
import {CgArrowDown} from "react-icons/cg"

const Accordion = ({ resource, isActive = false, toggleUp = () => {}, toggleDown = () => [] }) => {
  return (
    <div
      className="w-full flex flex-col mb-6 pb-6 border-b border-secondary">
      <div
        role="presentation"
        onClick={e => {
          if (isActive) {
            toggleUp() 
          } else {
            toggleDown()
          }
        }}
        className="flex items-center justify-between cursor-pointer">
        <h4 className="text-2xl text-primary">{resource.title}</h4>
        <span className="flex-shrink">
          <CgArrowDown />
        </span>
      </div>
      <div
        className={`overflow-hidden ${isActive ? 'h-auto' : 'h-0'}`}>
        <div
          sx={{
            p: {
              mb: 0,
            }
          }}
          className="py-4"
          dangerouslySetInnerHTML={{
            __html: resource.description
          }} />
      </div>
    </div>
  )
}

export default Accordion