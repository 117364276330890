/** @jsx jsx */
import {jsx} from "theme-ui"
import {useEffect, createRef} from "react"
import mapboxgl from 'mapbox-gl'
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

mapboxgl.accessToken = process.env.GATSBY_MAPBOX_TOKEN 

const Map = ({ center = [-100.7917, 46.8057], branches = [], atms = []}) => {
  const ref = createRef()
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: ref.current, // container ID
      style: 'mapbox://styles/mapbox/light-v10', // style URL
      center, // starting position [lng, lat]
      zoom: 11.75 // starting zoom
    });
    map.scrollZoom.disable()

    atms.forEach(atm => {
      const html = `
        <div class="p-4">
          <h4 class="mb-4">${atm.title}</h4>
            <strong>Address</strong><br />
            <p>${atm.atmFields?.address}</p>
          </div>
        </div>
      `
      new mapboxgl.Marker({
        color: '#91C96E'
      }).setLngLat([atm.atmFields.longitude,atm.atmFields.latitude])
        .setPopup(new mapboxgl.Popup().setHTML(html))
        .addTo(map);
    })

    branches.forEach(branch => {
      const html = `
        <div class="p-4">
          <h4 class="mb-4">${branch.title}</h4>
          <div>
            <strong>Contact</strong><br />
            <p>p: ${branch.branches.phone}</p>
          </div>
          <div>
            <strong>Address</strong><br />
            <p>${branch.branches?.address?.street}<br />${branch.branches?.address?.city}, ${branch.branches?.address?.state}</p>
          </div>
        </div>
      `
      new mapboxgl.Marker({
        color: '#1D3E4C'
      }).setLngLat([branch.branches.longitude,branch.branches.latitude])
        .setPopup(new mapboxgl.Popup().setHTML(html))
        .addTo(map);
    })

  }, [center, branches, atms, ref])
  return (
    <div
      ref={ref}
      style={{
        height: `calc(0.65*100vh)`
      }}
      className="w-full">
    </div>
  )
}

export default Map