import { default as React } from "react"
import qs from "qs"
import { stripHTML } from "~/helpers"

const AtmCard = ({ atm: { title = '', atmFields: { address = '' } } }) => {
  const link = qs.stringify({ q: stripHTML(address) })
  return (
    <div
      className="p-6 bg-gray-100 border-l-4 border-secondary">
      <strong>{title}</strong>
      <div>
        <a
          target="_blank"
          rel="noreferrer"
          className="cursor-pointer"
          href={`https://maps.google.com?${link}`}
          aria-label="address"
          dangerouslySetInnerHTML={{
            __html: address
          }} />
      </div>
    </div>
  )
}

export default AtmCard