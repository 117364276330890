/** @jsx jsx */
import {jsx} from "theme-ui"
import qs from "qs"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import { stripNumber } from "~/helpers"

const FullCard = ({ branch: { title = '', featuredImage, branches: { address = {}, phone = '', tollFree, fax, hours }}}) => {
  const link = qs.stringify({ q: Object.values(address).join(' ') })
  return (
    <div
      className="flex flex-wrap items-stretch px-8 mb-20">
      <div
        sx={{
          a: {
            color: 'blue.600'
          }
        }}
        className="flex flex-col order-1 w-full md:w-3/4 p-12 bg-gray-100 border-l-2 border-secondary">
        <h3 className="text-3xl text-primary mb-4">{ title }</h3>
        <div
          className="flex flex-wrap items-start -mx-4">
          <div
            className="w-full md:w-5/12 mb-8 px-4">
            <a
              target="_blank"
              rel="noreferrer"
              className="cursor-pointer"
              href={`https://maps.google.com?${link}`}>
              { address.street }<br />
              { `${address.city}, ${address.state} ${address.zip}` }
            </a>
          </div>
          <div
            className="w-full md:w-3/12 mb-8 px-4">
            <ul
              className="list-none p-0 m-0 leading-loose">
              <li>
                <span>p: </span>
                <a href={`tel:${stripNumber(phone)}`}>
                  {phone}
                </a>
              </li>
              { tollFree && (
                <li>
                  <span>tf: </span>
                  <a href={`tel:${stripNumber(tollFree)}`}>
                    {tollFree}
                  </a>
                </li>
              )}
              { fax && (
                <li>
                  <span>f: </span>
                  <span>
                    {fax}
                  </span>
                </li>
              )}
            </ul>
          </div>
          <div
            className="w-full md:w-4/12 px-4">
            <div
              dangerouslySetInnerHTML={{
                __html: hours
              }} />
          </div>
        </div>
      </div>
      <div className="order-0 md:order-2 w-full md:w-1/4">
        <GatsbyImage
          className="h-full"
          image={getImage(featuredImage?.node?.localFile)}
          alt={title} />
      </div>
    </div>
  )
}

export default FullCard