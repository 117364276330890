import {default as React, useState, Fragment} from "react"
import Accordion from "~/components/resources/accordion"

const Resources = ({ resources = [] }) => {
  const [activeResources, setActiveResources] = useState([])
  return (
    <Fragment>
    {resources.map(resource => {
      const isActive = !!activeResources.find(({ slug }) => slug === resource.slug)
      return (
        <Accordion
          key={resource.slug}
          resource={resource}
          isActive={isActive}
          toggleUp={() => {
            const index = activeResources.findIndex(({ slug }) => slug === resource.slug)
            const copied = [...activeResources]
            copied.splice(index, 1)
            setActiveResources(copied)
          }}
          toggleDown={() => {
            setActiveResources([...activeResources, resource])
          }} />
      )
    })} 
    </Fragment>
  )
}

export default Resources